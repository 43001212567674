import { Component,OnInit} from '@angular/core';
import {FileItem, FileUploader} from 'ng2-file-upload';
// declare var sessionId:any;
declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  sessionid: any;
  dfmessenger: any;
  file:any;
  name:any;
  url = 'https://lb4b-genie-proc.liveb4buy.com/lb4b/genie-proc/v1/Chatbot/upload'
  uploader = new FileUploader({
    url: this.url,
    maxFileSize: 1024* 1024 * 1
  });
  ngOnInit(){
    console.log("in app fn");
    let self = this
     
      window.addEventListener('df-response-received', function (event) {
          console.log(event)
          self.dfmessenger = event;
        });

      
      this.uploader.onCompleteItem =  (item:any, response:any, status:any, headers:any) => {
        console.log("values:",item,response,status);
      }
      this.uploader.onBeforeUploadItem = (item) => {
        item.withCredentials = false;
      }
    
      this.uploader.onAfterAddingFile = (file : FileItem) => {
        // console.log("session id is :", this.sessionid);
        console.log('***** onAfterAddingFile ******')
        file.file.name = this.dfmessenger.srcElement.attributes[5].nodeValue;
        console.log('file ', file)
      }

    }
    submit(){
      this.uploader.uploadAll()
      console.log("dfm:",this.dfmessenger.srcElement.attributes[5].nodeValue)
    }

    setSessionId(){}

  title = 'larf-genie-site';
}
